import { Box, IconButton } from "@chakra-ui/react"
import React from "react"
import { ReactComponent as CarouselArrow } from "~/images/CarouselArrow.svg"

interface ArrowNavigationProps {
  paginate: (direction: number) => void
}

const ArrowNavigation: React.FC<ArrowNavigationProps> = ({ paginate }) => {
  return (
    <Box className="s_arrow-navigation">
      <IconButton
        className="arrow"
        onClick={() => paginate(-1)}
        aria-label="prev image"
        transform="translateY(-50%) rotate(180deg)"
        pos="absolute"
        top="50%"
        icon={<CarouselArrow />}
        zIndex="2"
        left="20px"
        w="40"
        h="40"
        color={"#000"}
      />

      <IconButton
        className="arrow"
        onClick={() => paginate(1)}
        aria-label="next image"
        pos="absolute"
        top="50%"
        transform="translateY(-50%)"
        icon={<CarouselArrow />}
        zIndex="2"
        right="10px"
        w="40"
        h="40"
        color={"#000"}
      />
    </Box>
  )
}

export default ArrowNavigation
