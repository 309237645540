import { all } from "redux-saga/effects"
import watchProcessApplyEditorChanges from "./processApplyEditorChanges"
import {
  watchLogin,
  watchLoginResume,
  watchLoginSilently,
  watchLoginSuccess,
  watchLogout,
} from "./processAuth"
import { watchBootstrapPage } from "./processBootstrapPage"
import watchProcessCouponCode from "./processCouponCode"
import watchProcessDownloadProductTemplates from "./processDownloadProductTemplates"
import { watchDuplicateLineItem } from "./processDuplicateLineItem"
import { watchAnalyticEvents } from "./processAnalyticEvents"
import {
  watchQueueImageForProcessing,
  watchQueueImageForUploading,
} from "./processImagesInQueue"
import {
  watchAddLineItemToCart,
  watchCreateLineItem,
  watchCreateLineItemFromTemplate,
  watchInsertPageSpreadToLineItemAfter,
  watchInsertPageSpreadToLineItemBefore,
  watchRemoveIncompletePageSpreadsFromLineItem,
  watchRemoveLineItem,
  watchRemovePageSpreadFromLineItem,
} from "./processLineItems"
import { watchProcessPlaceOrder, watchProcessCreateOrder } from "./processOrder"
import watchOrderFromStore from "./processOrderFromStore"
import { watchPlaceReOrder } from "./processPlaceReOrder"
import {
  watchRestoreCartDataFromLocalStorage,
  watchRestoreCheckoutDataFromLocalStorage,
} from "./processRestoreDataFromLocalStorage"
import watchProcessStoreSearch from "./processStoreSearch"
import watchProcessUpdateFulfillment from "./processUpdateFulfillment"
import watchUpdateImageRegionImage from "./processUpdateImageRegionImage"
import watchUpdateLineItemOrientation from "./processUpdateLineItemOrientation"
import watchUpdateLineItemProduct from "./processUpdateLineItemProduct"
import watchUpdateLineItemProductTemplate from "./processUpdateLineItemProductTemplate"
import watchUpdateLineItemProductTemplateVariant from "./processUpdateLineItemProductTemplateVariant"
import watchUpdateMissingImagesForLineItem from "./processUpdateMissingImagesForLineItem"
import watchProcessUpdateOrderSummary from "./processUpdateOrderSummary"
import watchProcessUpdateStore from "./processUpdateStore"
import { watchCreateUpload, watchUploadUriImages } from "./processUploads"

function* rootSaga() {
  // register all sagas here
  yield all([
    watchQueueImageForProcessing(),
    watchQueueImageForUploading(),
    watchCreateUpload(),
    watchCreateLineItem(),
    watchRemoveLineItem(),
    watchCreateLineItemFromTemplate(),
    watchDuplicateLineItem(),
    watchUpdateLineItemProduct(),
    watchUpdateLineItemProductTemplate(),
    watchUpdateLineItemProductTemplateVariant(),
    watchUpdateLineItemOrientation(),
    watchUpdateMissingImagesForLineItem(),
    watchInsertPageSpreadToLineItemAfter(),
    watchInsertPageSpreadToLineItemBefore(),
    watchRemovePageSpreadFromLineItem(),
    watchRemoveIncompletePageSpreadsFromLineItem(),
    watchUpdateImageRegionImage(),
    watchProcessStoreSearch(),
    watchProcessPlaceOrder(),
    watchProcessCreateOrder(),
    watchProcessCouponCode(),
    watchProcessUpdateOrderSummary(),
    watchProcessDownloadProductTemplates(),
    watchProcessUpdateFulfillment(),
    watchProcessUpdateStore(),
    watchProcessApplyEditorChanges(),
    watchAddLineItemToCart(),
    watchLogin(),
    watchLoginSilently(),
    watchLoginResume(),
    watchRestoreCheckoutDataFromLocalStorage(),
    watchRestoreCartDataFromLocalStorage(),
    watchLoginSuccess(),
    watchLogout(),
    watchOrderFromStore(),
    watchBootstrapPage(),
    watchPlaceReOrder(),
    watchUploadUriImages(),
    watchAnalyticEvents(),
  ])
}

export default rootSaga
