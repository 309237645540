import { Box, BoxProps, Flex, useInterval } from "@chakra-ui/react"
import { nanoid } from "@reduxjs/toolkit"
import { AnimatePresence, motion, PanInfo } from "framer-motion"
import React, { useMemo, useState } from "react"
import ArrowNavigation from "./ArrowNavigation"
import "./Carousel.scss"
import Indicator from "./Indicator"

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exiting: { opacity: 0 },
}

const dragThreshold = 100

interface indicatorStyleProps extends BoxProps {
  borderFocusColor: string
}

interface WidthProps {
  md?: string | undefined
  lg?: string | undefined
  xl?: string | undefined
}

interface Props {
  images: {
    src: string
    alt?: string
    showthumbnails: "0" | "1"
    srcthumbnail: string
  }[]
  width: number | string
  height: number
  delay?: number
  carouselStyle?: BoxProps
  circleStyle?: indicatorStyleProps
  thumbnailStyle?: indicatorStyleProps
  thumbnailIndicatorSize?: WidthProps
}

const Carousel: React.FC<Props> = props => {
  const {
    images,
    width,
    height,
    delay = 0,
    carouselStyle = {},
    circleStyle = { borderColor: "white", borderFocusColor: "#2AAAE1" },
    thumbnailStyle = {
      borderColor: "#2AAAE1",
      borderFocusColor: "#1C95C9",
    },
    thumbnailIndicatorSize = { md: "80px" },
  } = props

  const displayableImages = images.filter(
    item => Number(item.showthumbnails) === 1
  )
  const firstImageIndex = 0
  const lastImageIndex = displayableImages.length - 1
  const [currentIndex, setCurrentIndex] = useState(0)
  const { src, alt } = displayableImages[currentIndex]
  const componentUUID = useMemo(nanoid, [])

  const handleNext = () => {
    setCurrentIndex(index => {
      if (index === displayableImages.length - 1) {
        return 0
      }
      return index + 1
    })
  }

  const selectImage = (index: number) => {
    setCurrentIndex(index)
  }

  const paginate = (newDirection: number) => {
    setCurrentIndex(prevIndex => {
      let newIndex = prevIndex + newDirection

      if (newIndex < 0) {
        return lastImageIndex
      } else if (newIndex >= displayableImages.length) {
        return firstImageIndex
      }

      return newIndex
    })
  }

  useInterval(() => {
    if (delay > 0) {
      handleNext()
    }
  }, delay)

  const handleDragEnd = (event: MouseEvent | TouchEvent, info: PanInfo) => {
    if (info.offset.x > dragThreshold) {
      paginate(-1)
    } else if (info.offset.x < -dragThreshold) {
      paginate(1)
    }
  }

  return (
    <Flex direction="column" p={4} className="s__carousel">
      <Box
        aria-label="carousel-container"
        className="s__carousel-container"
        zIndex={10}
        maxWidth={width}
        width={width}
        height={height}
        backgroundColor="#fff"
        maxHeight={height}
        display="flex"
        align="center"
        overflow="hidden"
        alignItems={"center"}
        justifyContent={"center"}
        {...carouselStyle}
        position="relative"
      >
        <AnimatePresence initial={false}>
          <motion.img
            id={`carousel-image-${componentUUID}-${currentIndex}`}
            key={`carousel-image-${componentUUID}-${currentIndex}`}
            initial="hidden"
            animate="visible"
            exit="exiting"
            src={src}
            alt={alt}
            width="100%"
            height="100%"
            variants={variants}
            style={{ objectFit: "cover", objectPosition: "center" }}
            drag="x"
            onDragEnd={handleDragEnd}
            dragConstraints={{ left: -1000, right: 1000 }}
            transition={{
              duration: 0,
            }}
            className="fade-animation"
          />
        </AnimatePresence>
        <ArrowNavigation paginate={paginate} />
      </Box>

      <Indicator
        displayableImages={displayableImages}
        currentIndex={currentIndex}
        selectImage={selectImage}
        thumbnailStyle={thumbnailStyle}
        circleStyle={circleStyle}
        thumbnailIndicatorSize={thumbnailIndicatorSize}
      />
    </Flex>
  )
}

export default Carousel
