import {
  Flex,
  Box,
  BoxProps,
  Circle,
  Image,
  useMediaQuery,
} from "@chakra-ui/react"
import React from "react"
import styled from "@emotion/styled"

interface ImageProps {
  src: string
  alt?: string
  srcthumbnail: string
}

interface thumbnailIndicatorStyleProps {
  isActive?: boolean
  borderImgFocusColor: string
  borderImgColor: string
  width?: WidthProps
}

interface Props {
  isMobile: boolean
  image: ImageProps
  currentIndex: number
  index: number
  selectImage: (index: number) => void
  thumbnailStyle: indicatorStyleProps
  circleStyle: indicatorStyleProps
  thumbnailIndicatorSize: WidthProps
}

interface indicatorStyleProps extends BoxProps {
  borderFocusColor: string
}

interface WidthProps {
  md?: string | undefined
  lg?: string | undefined
  xl?: string | undefined
}

interface TIndicatorProps {
  displayableImages: {
    src: string
    alt?: string
    showthumbnails: "0" | "1"
    srcthumbnail: string
  }[]
  currentIndex: number
  selectImage: (index: number) => void
  thumbnailStyle: indicatorStyleProps
  circleStyle: indicatorStyleProps
  thumbnailIndicatorSize: WidthProps
}

const TIndicator: React.FC<Props> = ({
  isMobile,
  image,
  currentIndex,
  index,
  selectImage,
  thumbnailStyle,
  circleStyle,
  thumbnailIndicatorSize,
}) => {
  const thumbnailColor = {
    color: thumbnailStyle.borderColor,
    focus: thumbnailStyle.borderFocusColor,
  }

  const circleIndicatorColor = {
    color: circleStyle.borderColor,
    focus: circleStyle.borderFocusColor,
  }

  if (isMobile) {
    return (
      <Circle
        aria-label="point indicator"
        key={`circle-${index}`}
        data-testid={`test-carousel-thumbnail-${index}`}
        size="8px"
        mx="2"
        bgColor={
          currentIndex === index
            ? circleIndicatorColor.focus
            : circleIndicatorColor.color
        }
        cursor="pointer"
        {...circleStyle}
        onClick={() => selectImage(index)}
      />
    )
  }

  return (
    <IndicatorHero
      className="s__indicator-image"
      as={Box}
      aria-label="indicator-image"
      key={`image-${index}`}
      data-testid={`test-image-carousel-thumbnail-${index}`}
      mr={2}
      cursor="pointer"
      borderWidth={2}
      bgColor="white"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      {...thumbnailStyle}
      isActive={currentIndex === index}
      borderImgColor={thumbnailColor.color}
      borderImgFocusColor={thumbnailColor.focus}
      width={thumbnailIndicatorSize}
      height={thumbnailIndicatorSize}
    >
      <Image
        className="s__indicator-thumbnail"
        src={image.srcthumbnail}
        alt={image.alt}
        objectFit="cover"
        width="100%"
        height="100%"
        onClick={() => selectImage(index)}
      />
    </IndicatorHero>
  )
}

const Indicator: React.FC<TIndicatorProps> = props => {
  const {
    displayableImages,
    currentIndex,
    selectImage,
    thumbnailStyle,
    circleStyle,
    thumbnailIndicatorSize,
  } = props
  const [isMobile] = useMediaQuery("(max-width: 768px)")
  return (
    <Flex
      mt={4}
      aria-label="indicator-wrapper"
      p={0}
      justifyContent={isMobile ? "center" : "flex-start"}
      className="s__indicator-wrapper"
    >
      {displayableImages.map((image, index) => (
        <TIndicator
          key={`indicator-${index}`}
          isMobile={isMobile}
          image={image}
          currentIndex={currentIndex}
          selectImage={selectImage}
          index={index}
          thumbnailStyle={thumbnailStyle}
          circleStyle={circleStyle}
          thumbnailIndicatorSize={thumbnailIndicatorSize}
        />
      ))}
    </Flex>
  )
}

export default Indicator

export const IndicatorHero = styled.div<thumbnailIndicatorStyleProps>(props => {
  const {
    isActive = false,
    width = {},
    borderImgFocusColor = "#2AAAE1",
    borderImgColor = "#fff",
  } = props
  const styles: any = {
    borderColor: isActive ? borderImgFocusColor : borderImgColor,

    "@media (min-width: 48rem)": {
      width: width && width.md ? width.md : undefined,
    },
    "@media (min-width: 64rem)": {
      width: width && width.lg ? width.lg : undefined,
    },
    "@media (min-width: 80rem)": {
      width: width && width.xl ? width.xl : undefined,
    },
  }

  return styles
})
