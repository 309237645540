import {
  AppEntityHydrated,
  ClientEntity,
  PrinticularOrder,
} from "@jackfruit/common"
import { parser as appButtonsParser } from "./shortCodeParsers/appButtons"
import { parser as accordionParser } from "./shortCodeParsers/accordion"
import { parser as badgeParser } from "./shortCodeParsers/badge"
import { parser as countrySelectParser } from "./shortCodeParsers/countrySelect"
import { parser as getStartedParser } from "./shortCodeParsers/getStarted"
import { parser as linkParser } from "./shortCodeParsers/link"
import { parser as navigationParser } from "./shortCodeParsers/navigation"
import { parser as orderParser } from "./shortCodeParsers/order"
import { parser as shareOnSocialParser } from "./shortCodeParsers/shareOnSocial"
import { parser as socialMediaButtonsParser } from "./shortCodeParsers/socialMediaButtons"
import { parser as carouselParser } from "./shortCodeParsers/carousel"

export type ShortCode =
  | "navigations"
  | "appButtons"
  | "orders"
  | "links"
  | "badges"
  | "getStarted"
  | "countrySelect"
  | "shareOnSocial"
  | "socialMediaButtons"
  | "carousel"
  | "accordion"

export const allShortCodes: ShortCode[] = [
  "navigations",
  "appButtons",
  "orders",
  "links",
  "badges",
  "getStarted",
  "countrySelect",
  "shareOnSocial",
  "socialMediaButtons",
  "carousel",
  "accordion",
]

export interface ShortCodeStateEntities {
  order?: PrinticularOrder
  client?: ClientEntity
  appConfig?: AppEntityHydrated
}

export type ShortCodeParser = (
  html: string,
  state: ShortCodeStateEntities
) => string

interface AllShortCodeParsers {
  [key: string]: ShortCodeParser
}

const shortCodeParsers: AllShortCodeParsers = {
  navigations: navigationParser,
  orders: orderParser,
  links: linkParser,
  badges: badgeParser,
  getStarted: getStartedParser,
  countrySelect: countrySelectParser,
  shareOnSocial: shareOnSocialParser,
  appButtons: appButtonsParser,
  socialMediaButtons: socialMediaButtonsParser,
  carousel: carouselParser,
  accordion: accordionParser,
}

export const replaceShortCodes = (
  textWithShortCode: string = "",
  state: ShortCodeStateEntities = {}
) => {
  const parsed = allShortCodes.reduce((someText, code) => {
    const parser = shortCodeParsers[code]

    const newText = someText ? parser(someText, state) : someText

    return newText
  }, textWithShortCode)

  return parsed
}
